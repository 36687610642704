import { FC } from "react";
import { Carousel } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";

import { StyledModal, ArrowContainer, CarouselContainer, Elem } from "./styles";

interface IProps {
  onClose: () => void;
  images: string[];
  initialChangedImage: number;
}

const CustomArrow = (props: any) => {
  const { onClick, className } = props;

  const isNext = className.includes("slick-next");

  return (
    <ArrowContainer onClick={onClick} isNext={isNext}>
      {isNext ? <RightOutlined /> : <LeftOutlined />}
    </ArrowContainer>
  );
};

const PreviewModal: FC<IProps> = ({ images, initialChangedImage, onClose }) => (
  <StyledModal onCancel={onClose} footer={null} width={1200} visible>
    <CarouselContainer>
      <Carousel
        initialSlide={initialChangedImage}
        slidesToShow={1}
        arrows={true}
        dots={false}
        nextArrow={<CustomArrow />}
        prevArrow={<CustomArrow />}
        draggable
      >
        {images.map((elem, key) => (
          <Elem key={key}>
            <img src={elem} alt={""} />
          </Elem>
        ))}
      </Carousel>
    </CarouselContainer>
  </StyledModal>
);

export default PreviewModal;
