import { Switch, Route } from "react-router-dom";

import HomePage from "../pages/Home";
import appRoutes from "app/routes";

const Home = () => (
  <Switch>
    <Route exact path={appRoutes.home} component={HomePage} />
  </Switch>
);

export default Home;
