import {
  getReportsByVin,
  createEntry,
  getReportsList,
  deleteReport,
} from "./reports";
import { login } from "./auth";

const requests = {
  getReportsByVin,
  login,
  getReportsList,
  createEntry,
  deleteReport,
};

export default requests;
