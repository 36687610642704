import { UploadFile } from "antd";
import { AxiosResponse } from "axios";

import constants from "modules/common/requests/const";
import axios from "app/axios";

interface ICreateEntry {
  vin: string;
  terminal: string;
  entry_date: string;
  attachment: UploadFile[];
}

export interface IResponseReportsByVinData {
  created_at: string;
  entry_date: string;
  id: number;
  images: string;
  terminal: string;
  updated_at: string;
  vin: string;
}

type GetReportsByVin = (
  vin: string
) => Promise<AxiosResponse<IResponseReportsByVinData>>;

export const getReportsByVin: GetReportsByVin = (vin) =>
  axios.get(`${constants.getReportsByVin}${vin}`);

export const createEntry = (data: ICreateEntry) => {
  const { vin, terminal, entry_date, attachment } = data;

  var formdata = new FormData();
  formdata.append("vin", vin);
  formdata.append("terminal", terminal);
  formdata.append("entry_date", entry_date);

  attachment.forEach((elem) => {
    formdata.append("attachment[]", elem?.originFileObj || "", elem.name);
  });

  return axios.post(constants.reports, formdata);
};

export const getReportsList = () => axios.get(constants.reports);

export const deleteReport = (id: string) =>
  axios.delete(`${constants.reports}/${id}`);
