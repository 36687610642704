import { useEffect, useState } from "react";
import { message } from "antd";

import { MainContainer } from "./styles";
import CreateEntry from "./components/CreateEntry";
import EntriesList from "./components/CreateEntry/EntriesList";
import requests from "modules/common/requests/";
import Header from "modules/common/components/Header";

export interface IData {
  id: string;
  vin: string;
  terminal: string;
  entry_date: string;
}

const ReportsList = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isLoadingData, setLoadingData] = useState(false);

  const fetchData = async () => {
    setLoadingData(true);
    const result = await requests.getReportsList();

    setData(Array.isArray(result.data.data) ? result.data.data : []);
    setLoadingData(false);
  };

  const deleteHandler = async (id: string) => {
    try {
      await requests.deleteReport(id);
      message.success("Удалено");
      await fetchData();
    } catch {
      message.error("Ошибка");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MainContainer>
      <Header isPrivatePage />
      <CreateEntry refreshData={fetchData} />
      <EntriesList
        data={data}
        deleteHandler={deleteHandler}
        isLoadingData={isLoadingData}
      />
    </MainContainer>
  );
};

export default ReportsList;
