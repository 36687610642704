import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  padding: 0 15px;
  max-width: 1000px;
`;

export const Controls = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
