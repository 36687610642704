import { useState } from "react";
import { message } from "antd";

import { MainContainer, ContentContainer } from "./styles";
import Header from "modules/common/components/Header";
import Search from "./components/Search";
import requests from "modules/common/requests/index";
import ChangedEntryContainer from "./components/ChangedEntryContainer";

export interface IChangedData {
  created_at: string;
  entry_date: string;
  id: number;
  images: string[];
  terminal: string;
  updated_at: string;
  vin: string;
  [key: string]: string | string[] | number;
}

const Home = () => {
  const [searchValue, setSearchValue] = useState("");
  const [changedData, setChangedData] = useState<IChangedData | null>(null);
  const [isLoading, setLoading] = useState(false);

  const onSearchInputChange = (value: string) => {
    setSearchValue(value);
  };

  const onSubmitSearch = async () => {
    setLoading(true);
    try {
      const result = await requests.getReportsByVin(searchValue);

      if (!result.data) {
        setChangedData(null);
        return;
      }

      setChangedData({
        ...result.data,
        images: JSON.parse(result.data.images || ""),
      });
    } catch {
      setChangedData(null);
      message.error("Not found");
    }
    setLoading(false);
  };

  return (
    <MainContainer>
      <Header />
      <ContentContainer>
        <Search
          onSearchInputChange={onSearchInputChange}
          onSubmitSearch={onSubmitSearch}
          searchValue={searchValue}
          isLoading={isLoading}
        />
        <ChangedEntryContainer data={changedData} />
      </ContentContainer>
    </MainContainer>
  );
};

export default Home;
