import { Route, Redirect } from "react-router-dom";
import { useContext } from "react";

import { AuthContext } from "modules/common/components/MainWrapper/MainWrapper";
import { adminRoutes } from "modules/admin/config/admin.router";

interface IPublicRoute {
  component: any;
  path: string;
  exact?: boolean;
}

const PublicRoute = ({ component: Component, path, ...rest }: IPublicRoute) => {
  const { userData } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        userData?.access_token ? (
          <Redirect to={adminRoutes.reportsList} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
