import { Switch, Redirect } from "react-router-dom";

import appRoutes from "app/routes";
import { joinPath } from "utils/path";
import Login from "../pages/Login";
import Reports from "../pages/ReportsList";
import PublicRoute from "modules/common/components/PublicRoute";
import PrivateRoute from "modules/common/components/PrivateRoute";

export const adminRoutes = {
  login: joinPath(appRoutes.admin, "login"),
  reportsList: joinPath(appRoutes.admin, "reports-list"),
};

const Admin = () => (
  <Switch>
    <Redirect exact path={appRoutes.admin} to={adminRoutes.login} />
    <PublicRoute path={adminRoutes.login} component={Login} />
    <PrivateRoute path={adminRoutes.reportsList} component={Reports} />
  </Switch>
);

export default Admin;
