import { Input, Button, message } from "antd";
import { ChangeEvent, useState, useContext } from "react";

import { MainContainer, ContentContainer, InputContainer } from "./styles";
import requests from "modules/common/requests";
import { AuthContext } from "modules/common/components/MainWrapper/MainWrapper";
import { set } from "utils/localStorage";
import axios from "app/axios";

interface IFormData {
  email: string;
  password: string;
}

const Login = () => {
  const { setUserData } = useContext(AuthContext);
  const [formData, setFormData] = useState<IFormData>({
    email: "",
    password: "",
  });
  const [isLoading, setLoading] = useState(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setFormData((formData) => ({
      ...formData,
      [id]: value,
    }));
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const result = await requests.login(formData);

      const data = result.data;

      if (!data) {
        return;
      }

      set("authData", JSON.stringify(result.data));
      setUserData(result.data);
      axios.defaults.headers.common["Authorization"] =
        `Bearer ${result.data.access_token}` || "";
    } catch {
      message.error("Неверный пароль или почта");
    }
    setLoading(false);
  };

  return (
    <MainContainer>
      <ContentContainer>
        <InputContainer>
          <Input
            value={formData.email}
            type={"email"}
            onChange={onChange}
            placeholder={"Email"}
            id={"email"}
          />
        </InputContainer>
        <InputContainer>
          <Input
            value={formData.password}
            type={"password"}
            onChange={onChange}
            placeholder={"Password"}
            id={"password"}
          />
        </InputContainer>
        <InputContainer>
          <Button onClick={onSubmit} loading={isLoading}>
            Submit
          </Button>
        </InputContainer>
      </ContentContainer>
    </MainContainer>
  );
};

export default Login;
