import styled from "styled-components";
import { Modal } from "antd";

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close {
    display: none;
  }
`;

interface IArrowContainer {
  isNext: boolean;
}

export const ArrowContainer = styled.div<IArrowContainer>`
  position: absolute;
  top: calc(50% - 7px);
  background: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;

  ${({ isNext }) => (isNext ? "right: -50px" : "left: -50px")}
`;

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const Elem = styled.div`
  display: flex;
  width: 100%;

  img {
    width: 100%;
    user-select: none;
  }
`;
