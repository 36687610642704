import { FC } from "react";

import { InputContainer, MainContainer } from "./styles";
import { Input, Button } from "antd";

interface IProps {
  onSearchInputChange: (value: string) => void;
  onSubmitSearch: () => void;
  searchValue: string;
  isLoading: boolean;
}

const Search: FC<IProps> = ({
  onSearchInputChange,
  onSubmitSearch,
  searchValue,
  isLoading,
}) => (
  <MainContainer>
    <h1>Search your car</h1>
    <InputContainer>
      <Input
        value={searchValue}
        onChange={(e) => onSearchInputChange(e.target.value)}
        id={"search"}
      />
      <Button onClick={onSubmitSearch} loading={isLoading}>
        Submit!
      </Button>
    </InputContainer>
  </MainContainer>
);

export default Search;
