import { FC, ReactChild, useEffect, useState, createContext } from "react";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";

import { MainContainer } from "./styles";
import { get, clear } from "utils/localStorage";
import LoadingContainer from "modules/common/components/LoadingContainer";
import appRoutes from "app/routes";
import axios from "app/axios";

interface IProps {
  children: ReactChild;
}

interface IJwtDecoded {
  [key: string]: string;
}

interface IUser {
  created_at: string;
  email: string;
  id: string;
  name: string;
  updated_at: string;
}

interface IUserData {
  access_token: string;
  user: IUser;
}

interface IAuthContext {
  userData: IUserData | null;
  setUserData: (data: IUserData | null) => void;
}

export const AuthContext = createContext<IAuthContext>({
  userData: {
    access_token: "",
    user: {
      created_at: "",
      email: "",
      id: "",
      name: "",
      updated_at: "",
    },
  },
  setUserData: () => {},
});

const MainWrapper: FC<IProps> = ({ children }) => {
  const [userData, setUserData] = useState<IUserData | null>(null);
  const [isComponentMounted, setComponentMounted] = useState(false);
  const history = useHistory();

  const logoutHandler = () => {
    clear();
    setUserData(null);
    history.push(appRoutes.admin);
  };

  const onMount = async () => {
    const authData = get("authData");
    const parsedAuthData = authData ? JSON.parse(authData) : null;
    const currentDate = +new Date();
    let tokenExpires;

    if (!parsedAuthData) {
      setComponentMounted(true);
      return;
    }

    const token = parsedAuthData.access_token;

    try {
      tokenExpires = (jwt_decode(token) as IJwtDecoded)?.exp;
    } catch (err) {
      logoutHandler();
      setComponentMounted(true);
      return;
    }

    if (currentDate >= +`${tokenExpires}000`) {
      logoutHandler();
      setComponentMounted(true);
      return;
    }

    axios.defaults.headers.common["Authorization"] = `Bearer ${token}` || "";

    setUserData(parsedAuthData);

    setComponentMounted(true);
  };

  const setAuthData = (userData: IUserData | null) => {
    setUserData(userData);
  };

  useEffect(() => {
    onMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isComponentMounted) {
    return (
      <MainContainer>
        <LoadingContainer />
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <AuthContext.Provider
        value={{
          userData,
          setUserData: setAuthData,
        }}
      >
        {children}
      </AuthContext.Provider>
    </MainContainer>
  );
};

export default MainWrapper;
