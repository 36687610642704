import { Table } from "antd";
import { FC } from "react";
import { DeleteOutlined } from "@ant-design/icons";

import { MainContainer, Controls } from "./styles";
import { IData } from "../../../ReportsList";

interface IProps {
  data: IData[];
  deleteHandler: (id: string) => void;
  isLoadingData: boolean;
}

const EntriesList: FC<IProps> = ({ data, deleteHandler, isLoadingData }) => (
  <MainContainer>
    <Table
      dataSource={data}
      loading={isLoadingData}
      columns={[
        {
          dataIndex: "action",
          key: "id",
          render: (_, data) => (
            <Controls>
              <DeleteOutlined onClick={() => deleteHandler(data.id)} />
            </Controls>
          ),
        },
        {
          title: "VIN",
          dataIndex: "vin",
          key: "vin",
        },
        {
          title: "Терминал",
          dataIndex: "terminal",
          key: "terminal",
        },
        {
          title: "Дата создания",
          dataIndex: "entry_date",
          key: "entry_date",
        },
      ]}
      locale={{
        emptyText: "Нет данных",
      }}
    />
  </MainContainer>
);

export default EntriesList;
