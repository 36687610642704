import { FC } from 'react';
import { SpinnerElement } from './styles';

interface IProps {
  width: number;
  backgroundColor: string;
  customSpinnerColor?: string;
}

const Spinner: FC<IProps> = ({ backgroundColor, width, customSpinnerColor }) => (
  <SpinnerElement $background={backgroundColor} $width={width} $customSpinnerColor={customSpinnerColor} />
);

export default Spinner;
