import { Switch, Route } from "react-router-dom";

import appRoutes from "./routes";
import HomePage from "modules/home/config/home.router";
import AdminPage from "modules/admin/config/admin.router";

const Router = () => {
  return (
    <Switch>
      <Route exact path={appRoutes.home}>
        <HomePage />
      </Route>
      <Route path={appRoutes.admin}>
        <AdminPage />
      </Route>
    </Switch>
  );
};

export default Router;
