import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  background: rgba(120, 120, 120, 0.1);
  height: 60px;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }
`;
