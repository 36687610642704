import {
  Input,
  DatePicker,
  Button,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import { useState, ChangeEvent, FC } from "react";
import moment, { Moment } from "moment";

import { ContentContainer, InputContainer } from "./styles";
import requests from "modules/common/requests/";

interface IFormData {
  vin: string;
  terminal: string;
  entry_date: string;
  attachment: UploadFile[];
}

const initialFormData: IFormData = {
  vin: "",
  terminal: "",
  entry_date: "",
  attachment: [],
};

interface IProps {
  refreshData: () => void;
}

const CreateEntry: FC<IProps> = ({ refreshData }) => {
  const [formData, setFormData] = useState<IFormData>(initialFormData);
  const [isLoading, setLoading] = useState(false);

  const isInvalid =
    formData.vin.length < 8 ||
    !formData.terminal ||
    !formData.entry_date ||
    !formData.attachment.length;

  const onChangeTextInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setFormData((formData) => ({
      ...formData,
      [id]: value,
    }));
  };

  const changeDate = (date: Moment | null) => {
    if (!date) {
      return;
    }

    setFormData((formData) => ({
      ...formData,
      entry_date: date.format("YYYY-MM-DD"),
    }));
  };

  const changeFiles: UploadProps["onChange"] = (info) => {
    let newFileList = [...info.fileList];

    setFormData((formData) => ({
      ...formData,
      attachment: newFileList,
    }));
  };

  const onCreate = async () => {
    setLoading(true);
    try {
      await requests.createEntry(formData);
      message.success("Запись успешно создана");
      setFormData(initialFormData);
      refreshData();
    } catch {
      message.error("Ошибка");
    }

    setLoading(false);
  };

  return (
    <ContentContainer>
      <h1>Регистрация VIN кода</h1>
      <InputContainer>
        <Input
          value={formData.vin}
          placeholder={"VIN код"}
          onChange={onChangeTextInput}
          id={"vin"}
        />
      </InputContainer>
      <InputContainer>
        <Input
          value={formData.terminal}
          placeholder={"Терминал"}
          onChange={onChangeTextInput}
          id={"terminal"}
        />
      </InputContainer>
      <InputContainer>
        <DatePicker
          value={formData.entry_date ? moment(formData.entry_date) : null}
          placeholder={"Дата записи"}
          id={"entry_date"}
          onChange={(date) => changeDate(date)}
        />
      </InputContainer>
      <InputContainer>
        <Upload
          onChange={changeFiles}
          customRequest={() => new Promise((resolve) => resolve(""))}
          fileList={formData.attachment}
          multiple
        >
          <Button>Добавить картинку</Button>
        </Upload>
      </InputContainer>
      <InputContainer>
        <Button disabled={isInvalid} onClick={onCreate} loading={isLoading}>
          Создать запись
        </Button>
      </InputContainer>
    </ContentContainer>
  );
};

export default CreateEntry;
