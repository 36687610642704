import Spinner from 'modules/common/components/Spinner';

import { MainContainer } from './styles';

const LoadingContainer = () => (
  <MainContainer>
    <Spinner width={50} backgroundColor={'#f8f8fb'} customSpinnerColor={'#d52b41'} />
  </MainContainer>
);

export default LoadingContainer;
