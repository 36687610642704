import constants from "modules/common/requests/const";
import axios from "app/axios";

interface ILoginData {
  email: string;
  password: string;
}

export const login = (loginData: ILoginData) =>
  axios.post(constants.login, loginData);
