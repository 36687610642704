import { FC, useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import moment from "moment";

import {
  MainContainer,
  ImagesContainer,
  ContentContainer,
  Info,
  InfoContainer,
} from "./styles";
import { IChangedData } from "../../Home";
import PreviewModal from "../PreviewModal";

interface IProps {
  data: IChangedData | null;
}

const infoElements = [
  {
    label: "VIN",
    key: "vin",
  },
  {
    label: "Container Entry Date",
    key: "entry_date",
  },
  {
    label: "Container Open Date",
    key: "created_at",
  },
  {
    label: "Terminal",
    key: "terminal",
  },
];

const ChangedEntryContainer: FC<IProps> = ({ data }) => {
  const [changedImageKey, setChangedImageKey] = useState<number | null>(null);

  const changeImageKey = (key: number) => {
    setChangedImageKey(key);
  };

  const onClosePreviewModal = () => {
    setChangedImageKey(null);
  };

  if (!data) {
    return null;
  }
  return (
    <ContentContainer>
      {typeof changedImageKey === "number" && (
        <PreviewModal
          images={data.images}
          initialChangedImage={changedImageKey}
          onClose={onClosePreviewModal}
        />
      )}
      <MainContainer>
        <ImagesContainer>
          {data?.images.map((elem, key) => (
            <img
              src={elem}
              key={key}
              alt={""}
              onClick={() => changeImageKey(key)}
            />
          ))}
        </ImagesContainer>
        <InfoContainer>
          {infoElements.map((elem) => {
            const { key, label } = elem;

            return (
              <Info>
                <RightOutlined />
                <span>{label}:</span>
                {key === "created_at"
                  ? moment(data[key]).format("YYYY-MM-DD")
                  : data[key]}
              </Info>
            );
          })}
        </InfoContainer>
      </MainContainer>
    </ContentContainer>
  );
};

export default ChangedEntryContainer;
