import styled from "styled-components";

export const ContentContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 15px 0px rgba(71, 67, 71, 1);
  border-radius: 5px;

  h1 {
    font-size: 16px;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;

  :first-child {
    margin-top: 0;
  }

  .ant-picker {
    width: 100%;
  }

  .ant-upload-text-icon {
    display: none;
  }
`;
