import styled from "styled-components";

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 100px;
`;

export const MainContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
`;

export const ImagesContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  img {
    width: 100%;
    cursor: pointer;
  }
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Info = styled.div`
  font-size: 14px;

  span {
    font-weight: 500;
    margin: 0 10px 10px 10px;
  }
`;
