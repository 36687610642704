import { BrowserRouter } from "react-router-dom";
import MainWrapper from "modules/common/components/MainWrapper";

import Router from "app/router";

const App = () => (
  <BrowserRouter>
    <MainWrapper>
      <Router />
    </MainWrapper>
  </BrowserRouter>
);

export default App;
