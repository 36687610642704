import { FC, useContext } from "react";
import { Button } from "antd";

import { clear } from "utils/localStorage";
import { MainContainer } from "./styles";
import { AuthContext } from "modules/common/components/MainWrapper/MainWrapper";

interface IProps {
  isPrivatePage?: boolean;
}

const Header: FC<IProps> = ({ isPrivatePage }) => {
  const { setUserData } = useContext(AuthContext);

  const logoutHandler = () => {
    clear();
    setUserData(null);
  };

  return (
    <MainContainer>
      <h1>Lev-Shipping</h1>
      {isPrivatePage && <Button onClick={logoutHandler}>Выйти</Button>}
    </MainContainer>
  );
};

export default Header;
